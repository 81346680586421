import React from 'react'
import type { FC } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Container, Flex, Heading, Box } from '@vtex/store-ui'
import Layout from 'src/components/Layout'

const NotFoundPage: FC = () => (
  <Layout>
    <GatsbySeo
      title="Página não encontrada"
      description="Page not found"
      noindex
      nofollow
    />
    <Container>
      <Flex
        p={2}
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '225px',
          marginBottom: '50px',
        }}
      >
        <Heading as="h1" sx={{ textAlign: 'center' }}>
          :( <br /> Oops! Não encontramos nenhum <br /> resultado para a sua
          busca.
        </Heading>
        <Box id="avon-omni-shelf" />
      </Flex>
    </Container>
  </Layout>
)

export default NotFoundPage
